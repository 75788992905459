/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';
import LoginLayout from '@components/layouts/LoginContainer';
import { useAuthContext } from '@context/AuthContext';
import Swal from 'sweetalert2';
import Router from 'next/router';
import AuthServices from '@services/auth-services/AuthServices';
import { Button, Input, Label } from '@/components/elements';
import TrunstileComponent from '@/components/elements/TrunstileComponent';
import Form, { useForm } from 'rc-field-form';
import Link from 'next/link';
import Image from 'next/image';
import axios from 'axios';
import { getCookie, setCookie } from 'cookies-next';
import { StorageKeys } from '@data/constant';

const Login: React.FC = () => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const { saveAuth, saveDataUser, setImgProfile } = useAuthContext();


  const fetchPermission = async (id: number, AccessToken: string) => {
    axios.get(`${process.env.NEXT_PUBLIC_API_URL}/permission/role/${id}`,{
      headers: {
        Authorization : `Bearer ${AccessToken}`
      }
    }).then((res) => {
      const { data } = res.data;
      if (id === 1) {
        localStorage.setItem('permission', JSON.stringify([
          {
            "id": 1,
            "name_th": "เข้าสู่ระบบ",
            "permission_role": {
              "feature_id": 1,
              "read": 1,
              "create": 1,
              "update": 1,
              "delete": 1,
            }
          },
          {
            "id": 2,
            "name_th": "Dashboard ผู้ใช้งาน",
            "permission_role": {
              "feature_id": 2,
              "read": 1,
              "create": 1,
              "update": 1,
              "delete": 1,
            }
          },
          {
            "id": 3,
            "name_th": "Dashboard สมาธิ",
            "permission_role": {
              "feature_id": 3,
              "read": 1,
              "create": 1,
              "update": 1,
              "delete": 1,
            }
          },
          {
            "id": 4,
            "name_th": "แบนเนอร์",
            "permission_role": {
              "feature_id": 4,
              "read": 1,
              "create": 1,
              "update": 1,
              "delete": 1,
            }
          },
          {
            "id": 5,
            "name_th": "สาขา",
            "permission_role": {
              "feature_id": 5,
              "read": 1,
              "create": 1,
              "update": 1,
              "delete": 1,
            }
          },
          {
            "id": 6,
            "name_th": "FAQ",
            "permission_role": {
              "feature_id": 6,
              "read": 1,
              "create": 1,
              "update": 1,
              "delete": 1,
            }
          },
          {
            "id": 7,
            "name_th": "หมวดหมู่ FAQ",
            "permission_role": {
              "feature_id": 7,
              "read": 1,
              "create": 1,
              "update": 1,
              "delete": 1,
            }
          },
          {
            "id": 8,
            "name_th": "แกลเลอรี่",
            "permission_role": {
              "feature_id": 8,
              "read": 1,
              "create": 1,
              "update": 1,
              "delete": 1,
            }
          },
          {
            "id": 9,
            "name_th": "คณะผู้บริการ",
            "permission_role": {
              "feature_id": 9,
              "read": 1,
              "create": 1,
              "update": 1,
              "delete": 1,
            }
          },
          {
            "id": 10,
            "name_th": "เนื้อหาต่างๆ",
            "permission_role": {
              "feature_id": 10,
              "read": 1,
              "create": 1,
              "update": 1,
              "delete": 1,
            }
          },
          {
            "id": 11,
            "name_th": "อบรม",
            "permission_role": {
              "feature_id": 11,
              "read": 1,
              "create": 1,
              "update": 1,
              "delete": 1,
            }
          },
          {
            "id": 13,
            "name_th": "กิจกรรม",
            "permission_role": {
              "feature_id": 13,
              "read": 1,
              "create": 1,
              "update": 1,
              "delete": 1,
            }
          },
          {
            "id": 14,
            "name_th": "สมาธิแคมเปญ",
            "permission_role": {
              "feature_id": 14,
              "read": 1,
              "create": 1,
              "update": 1,
              "delete": 1,
            }
          },
          {
            "id": 15,
            "name_th": "ผู้ใช้งานทั่วไป",
            "permission_role": {
              "feature_id": 15,
              "read": 1,
              "create": 1,
              "update": 1,
              "delete": 1,
            }
          },
          {
            "id": 16,
            "name_th": "ปรับสิทธิ์แอพพลิเคชั่น",
            "permission_role": {
              "id": 187,
              "feature_id": 16,
              "read": 1,
              "create": 1,
              "update": 1,
              "delete": 1,
            }
          },
          {
            "id": 17,
            "name_th": "ประเภทสมาชิก",
            "permission_role": {
              "feature_id": 17,
              "read": 1,
              "create": 1,
              "update": 1,
              "delete": 1,
            }
          },
          {
            "id": 18,
            "name_th": "ประวัติการใช้งาน",
            "permission_role": {
              "feature_id": 18,
              "read": 1,
              "create": 1,
              "update": 1,
              "delete": 1,
            }
          },
          {
            "id": 25,
            "name_th": "คณะกรรมการ",
            "permission_role": {
              "feature_id": 25,
              "read": 1,
              "create": 1,
              "update": 1,
              "delete": 1,
            }
          },
          {
            "id": 26,
            "name_th": "คณะทำงาน",
            "permission_role": {
              "feature_id": 26,
              "read": 1,
              "create": 1,
              "update": 1,
              "delete": 1,
            }
          }
        ]));
      }else{
        localStorage.setItem('permission', JSON.stringify(data));
      }
    });
  };

  const onSubmit = async () => {
    form.validateFields().then(async (values) => {
      setLoading(true);
      try {
        const res = await AuthServices.loginWithEmail({
          email: values.email,
          password: values.password,
        });
        const loginRole = await AuthServices.loginForRoleID({
          email: values.email,
          password: values.password,
        });
        if (res?.user && loginRole?.payload) {
          const user = res?.user as any; // NOSONAR
          await fetchPermission(loginRole.payload.data.user_role.role_id, user.stsTokenManager.accessToken);
          setTimeout(() => {
            saveAuth(
              user.stsTokenManager.accessToken,
              user.stsTokenManager.refreshToken,
            );
            setCookie(StorageKeys.User, loginRole?.payload?.data);
            setCookie(StorageKeys.UserImg, loginRole?.payload?.img_profile);
            const userDB = JSON.stringify(getCookie(StorageKeys.User) as string);
            const userParse = JSON.parse(userDB);
            const userImg = JSON.stringify(getCookie(StorageKeys.UserImg) as string);
            const imgParse = JSON.parse(userImg);
            saveDataUser(userParse);
            setImgProfile(imgParse);
            Router.replace('/');
          }, 1000);
        } else {
          Swal.fire({
            icon: 'error',
            // @ts-ignore
            title: 'เข้าสู่ระบบไม่สำเร็จ',
            showConfirmButton: false,
            timer: 1500,
          });
          saveAuth(undefined, undefined);
          saveDataUser(undefined);
          setImgProfile(undefined);
          setLoading(false);
        }
      } catch (error) {
        // @ts-ignore
        if (error.data) {
          Swal.fire({
            icon: 'error',
            // @ts-ignore
            title: error.data.message,
          });
          saveAuth(undefined, undefined);
          saveDataUser(undefined);
          setImgProfile(undefined);
          setLoading(false);
        }
      }
    });
  };

  return (
    <LoginLayout>
      <section className="w-full bg-gray-50">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="w-full grid grid-cols-1 lg:grid-cols-2 bg-white rounded-lg shadow md:mt-0 sm:max-w-screen-xl xl:p-0">
            <div className="flex flex-col items-center justify-center  px-6 space-y-4 md:space-y-6 sm:px-8 md:px-10 xl:px-24">
              <div className="text-center">
                <img className="w-16 mx-auto" src="will-logo.webp" alt="logo" />
                <h1 className="text-3xl" data-testid="h1">
                  Samathi101 - Backoffice
                </h1>
                <p className="">เข้าสู่ระบบหลังบ้าน</p>
              </div>
              <Form
                form={form}
                onFinish={onSubmit}
                className="space-y-4 w-full"
              >
                <div>
                  <Label
                    name="email"
                    label="email"
                    labelHidden
                    rules={[
                      { required: true, message: 'กรุณากรอกอีเมล' },
                      {
                        type: 'email',
                        message: 'รูปแบบอีเมลไม่ถูกต้อง',
                      },
                    ]}
                  >
                    <Input
                      id="email"
                      placeholder="อีเมล"
                      type="email"
                      data-testid="email"
                    />
                  </Label>
                </div>
                <div>
                  <Label
                    name="password"
                    label="password"
                    labelHidden
                    rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน' }]}
                  >
                    <Input
                      id="password"
                      type="password"
                      placeholder="รหัสผ่าน"
                      visibilityToggle={{
                        visible: passwordVisible,
                        onVisibleChange: setPasswordVisible,
                      }}
                      data-testid="password"
                    />
                  </Label>
                </div>
                <TrunstileComponent />
                <Button
                  block
                  htmlType="submit"
                  shape="rounded"
                  onClick={() => onSubmit}
                  loading={loading}
                  id="login-button"
                  data-testid="login-button"
                >
                  {!loading && (
                    <span className="indicator-label">เข้าสู่ระบบ</span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      <span>รอสักครู่...</span>
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </Button>
                <div className="text-center">
                  <Link
                    href="/forgot-password"
                    id="forgot-pass"
                    className="hover:text-primary"
                  >
                    ลืมรหัสผ่าน
                  </Link>
                </div>
              </Form>
            </div>
            <div className="relative hidden w-full bg-indigo-700 md:block">
              <Image
                src="/login-cover.webp"
                alt="bg"
                width={768}
                height={865}
              />
            </div>
          </div>
        </div>
      </section>
    </LoginLayout>
  );
};

export default Login;
