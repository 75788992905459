/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

const LoginContainer: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {

  return (
    <div className="h-full flex items-center justify-center flex-col flex-root">
      <div className="w-full d-flex flex-center flex-col lg:flex-row">
        {children}
      </div>
    </div>
  );
};

export default LoginContainer;
