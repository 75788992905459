/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

declare const turnstile: any;

// *** DUMMY SITE KEYS ***
// -- Always passes
// const SITEKEY = '1x00000000000000000000AA';
// -- Always blocks
// const SITEKEY = "2x00000000000000000000AB";
// -- Forces an interactive challenge
// const SITEKEY = "3x00000000000000000000FF";
const SITEKEY = process.env.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY;

export enum Execution {
    Render = 'render',
    Execute = 'execute',
}

function printColoredMsg(msg: string, color: string) {
    console.log(`%c${msg}`, `color: ${color};`);
}

export default function useCloudflareTurnstile(
    action: string, containerId: string, execution: Execution) {
    
    const [turnstileToken, setTurnstileToken] = useState<string>('');
    const [turnstileWidgetId, setTurnstileWidgetId] = useState<string>('');

    function prepareTurnstileWidget() {
        const widgetId = turnstile.render(`#${containerId}`, {
            sitekey: SITEKEY,
            language: 'en',
            action,
            execution,
            callback: function (token: string) {
                printColoredMsg(`${widgetId}: Challenge Success ${token}`, 'mediumseagreen');
                token ? setTurnstileToken(token) : setTurnstileToken('');
            },
            'expired-callback': function () {
                printColoredMsg('Expired Callback', 'orangered');

                // refresh token
                setTurnstileToken('');
                turnstile.reset(`#${containerId}`);
                turnstile.execute(`#${containerId}`);
            },
        });
        setTurnstileWidgetId(widgetId);
        printColoredMsg(`new widgetId #${widgetId} ---`, 'steelblue');
    }

    // Remove Cloudflare Turnstile widget when component unmounts
    useEffect(() => {
        return () => {
            printColoredMsg('--- cleanup Turnstile ---', 'salmon');
            if (turnstileWidgetId) {
                printColoredMsg(`Removed Turnstile widget: ${turnstileWidgetId}`, 'salmon');
            }
        };
    }, [turnstileWidgetId]);

    return {
        turnstileToken,
        setTurnstileToken,
        turnstileWidgetId,
        setTurnstileWidgetId,
        prepareTurnstileWidget,
    };
}
