/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import useCloudflareTurnstile, { Execution } from './useCloudflareTurnstile';
import Script from 'next/script';

const TurnstileComponent: React.FC = () => {
  // for Cloudflare Turnstile
  const { prepareTurnstileWidget } = useCloudflareTurnstile(
    'cf-turnstile',
    'turnstile-container',
    Execution.Render,
  );

  return (
    <>
      <Script
        src="https://challenges.cloudflare.com/turnstile/v0/api.js"
        defer
        onReady={prepareTurnstileWidget}
      ></Script>
      <div
        className="flex justify-center cf-turnstile"
        id="turnstile-container"
      ></div>
    </>
  );
};

export default TurnstileComponent;
