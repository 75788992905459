/* eslint-disable @typescript-eslint/no-explicit-any */
// firebase
import {
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from 'firebase/auth';

// utils
import { instance, axios } from '@utils/axios';
import { firebaseAuth } from '@utils/firebase';

const AuthService = {
  authorization: async (user: any) => {
    try {
      const userUID = user.uid;

      const res = await instance.post(
        '/user/login',
        {
          firebase_uid: userUID,
        },
        {
          headers: { Authorization: `Bearer ${user.accessToken}` },
        },
      );

      const { data, status } = res;

      if (status === 200) {
        const isDeletedAccount = data.data.active_status;

        const returnData = {
          isDeletedAccount,
        };

        return returnData;
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        window.location.reload();
      }
      console.log('getEvent error', error);
    }

    return null;
  },
  loginWithEmail: async (loginParams: any) => {
    const { email, password } = loginParams;

    try {
      const response = await signInWithEmailAndPassword(
        firebaseAuth,
        email,
        password,
      );
      const { user } = response;

      return {
        user,
      };
    } catch (e: any) {
      if (!e) {
        return;
      }
      return {
        error: e.message,
      };
    }
  },
  loginForRoleID: async (loginParams: any) => {
      const { email, password } = loginParams;
      try {
        const response = await axios.post('/auth/login', {
          email: email,
          password: password,
        });
        return response?.data;
      } catch (e: any) {
        if (!e) {
          return;
        }
        return {
          error: e.message,
        };
      }
    },
  forgotPassword: async (email: any) => {
    try {
      const response = await sendPasswordResetEmail(firebaseAuth, email);
      return response;
    } catch (e: any) {
      if (!e) {
        return;
      }
      return {
        error: e.message,
      };
    }
  },
  confirmThePasswordReset: async (oobCode: string, newPassword: string) => {
    if (!oobCode && !newPassword) return;

    return await confirmPasswordReset(firebaseAuth, oobCode, newPassword);
  },
  resetPassword: async (email: any, password: any) => {
    try {
      const res = await instance.get('/auth/forgot-password', {
        data: {
          email: email,
          password: password,
        },
        headers: {
          'api-key': process.env.NEXT_PUBLIC_API_KEY,
        },
      });

      const { status } = res;
      if (status === 200) {
        return true;
      }
    } catch (error: any) {
      console.log('first', error);
    }
  },
  logout: async () => {
    await signOut(firebaseAuth);
  },
};

export default AuthService;
